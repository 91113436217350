﻿import iframeResponsiveInModalTemplate from './iframeResponsiveInModal.html';
import iframeResponsivePlayerCodeTemplate from './iframeResponsivePlayerCode.html';
import iframeResponsiveUploadTemplate from './iframeResponsiveUpload.html';
import iframeResponsiveUploadModalTemplate from './iframeResponsiveUploadModal.html';
import iframeResponsiveVideoTemplate from './iframeResponsiveVideo.html';
import iframeResponsiveVideoBackgroundTemplate from './iframeResponsiveVideoBackground.html';

export {
    iframeResponsiveInModalTemplate,
    iframeResponsivePlayerCodeTemplate,
    iframeResponsiveUploadTemplate,
    iframeResponsiveUploadModalTemplate,
    iframeResponsiveVideoTemplate,
    iframeResponsiveVideoBackgroundTemplate,
};
